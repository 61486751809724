<template>
  <item-list-view
      :api="api"
      :headers="headers"
      id-key="id"
      title="Болезни"
      search-placeholder="Поиск по названию"
      :allowed-search="true"
      :allowed-create="true"
      :allowed-remove="true"
      :create-route="{ name: 'Disease', params: { id: 'new' }}"
      update-route="Disease"
      remove-title="Удаление болезни"
      remove-message="Вы действительно хотите удалить болезнь '<%= item.name %>'?"
  >
    <template v-slot:item.name="{ item }">
      <router-link
        class="text-decoration-none"
        style="padding: 8px 0; display: block"
        :to="{ name: 'Disease', params: { id: item.id }}"
      >
        {{ item.name }}
      </router-link>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip small label dark :color="item.status === statuses.PUBLISHED ? 'green' : 'red'">
        {{ item.status === statuses.PUBLISHED ? 'Да' : 'Нет' }}
      </v-chip>
    </template>
  </item-list-view>
</template>

<script>
import api from '../../services/diseases'
import ItemListView from '../../components/ItemListView'
import statuses from './articleStatus'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Название', value: 'name' },
      { text: 'Опубликован', value: 'status' },
      { text: '', value: 'action', align: 'end' }
    ],
    statuses: statuses
  })
}
</script>
