import api from '../utils/api'
import omit from 'lodash/omit'
import get from 'lodash/get'

const BASE_URL = 'what-do-we-treat/diseases'

export default {
  get (id) {
    return api.get(`${BASE_URL}/${id}`)
  },
  getAll (params) {
    return api.get(BASE_URL, { params: params })
  },
  update (id, disease) {
    return api.patch(`${BASE_URL}/${id}`, disease)
  },
  create (disease) {
    return api.post(BASE_URL, disease)
  },
  remove (id) {
    return api.delete(`${BASE_URL}/${id}`)
  },
  copy(uuid) {
    return api.post(`${BASE_URL}/${uuid}/copy`)
  },
  createModel () {
    return {
      status: 'draft', // fixme: use const
      name: null,
      slug: null,
      facts: null,
      about: null,
      whenToSeeADoctor: null,
      diagnosis: null,
      treat: null,
      rehab: null,
      howToPrepare: null,
      symptoms: [],
      specialities: [],
      doctors: [],
      procedures: [],
      photo: null,
      relatedDiseases: []
    }
  },
  toDto (entity) {
    let n = omit(entity, ['photo', 'doctors', 'specialities', 'procedures', 'symptoms', 'relatedDiseases'])
    n.photoId = get(entity, 'photo.id', null)
    n.doctorIds =  entity.doctors.map(s => s.id)
    n.specialityIds =  entity.specialities.map(s => s.id)
    n.procedureIds =  entity.procedures.map(s => s.id)
    n.symptomIds =  entity.symptoms.map(s => s.id)
    n.relatedDiseaseIds = entity.relatedDiseases.map(s => s.id)
    return n
  },
  getPreviewPath (diseaseId) {
    return `/what-do-we-treat/diseases/${diseaseId}`
  },
  getPublicPath (diseaseSlug) {
    return `/what-do-we-treat/diseases/${diseaseSlug}`
  }
}
